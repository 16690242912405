import React from "react"
import { FormattedMessage } from "gatsby-plugin-react-intl"

import LegalDocument from "../components/LegalDocument"

const TermsAndConditionsPage = () => (
  <LegalDocument title={<FormattedMessage id="legal.terms.title" />}>
    <LegalDocument.Content name="terms" />
  </LegalDocument>
)

export default TermsAndConditionsPage
